import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext"
import { useContext } from 'react'
import G1 from '../../../../images/G_01.webp'
import G2 from '../../../../images/G_02.webp'
import G3 from '../../../../images/G_03.webp'
import G4 from '../../../../images/G_04.webp'


export default function BloqueDescrip() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='bg-landing'>


            <section className='max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5' >

                {/* Bloque texto + imagen  */}
                <div className="pt-5">
                    <div className="pt-2  ">
                        <div className='pt-5 text30 text-center  sec-green-text px-title '>
                        <h2 className="" 
                        data-aos="zoom-y-out"
                        >
                            <span className='font-bold'>AirCO2</span>{t("landingCarbon.titleDescription")}</h2>
                            </div>
                        <h3 className="pt-4 text16 sec-green-text " 
                        data-aos="zoom-y-out"
                        >{t("landingCarbon.subTitleDescription")} </h3>
                    </div>
                    <div className="mt-5 pt-5">

                        {/* 1 */}
                        <article className="max-w-sm mx-auto  md:max-w-none md:flex md:items-center gap-5 py-5">
                            {/* Image */}
                            <div className="relative block group overflow-hidden md:w-1/2" data-aos="fade-down">

                                <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={G1} width="540" height="340" alt="amazonas" style={{ borderRadius: '10px' }} />
                                <div className="absolute top-6 right-6">

                                </div>
                            </div>
                            {/* Content */}
                            <div className="md:w-1/2 mt-4 md:mt-1"
                            data-aos="fade-left"
                            >
                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle1")}</p>
                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle1b")}</p>
                                <p className=" sec-grey-text text14 grow" >
                                    <span className='font-bold pr-1'>
                                    {t("landingCarbon.descriptionSubTitle1")} 
                                    </span>
                                    <span>
                                    {t("landingCarbon.descriptionSubTitle1b")}
                                    </span>
                                    </p>

                            </div>
                        </article>

                        {/* 2 */}
                        <article className="max-w-sm mx-auto  md:max-w-none md:flex md:items-center gap-5 py-5">
                            {/* Image */}
                            <div className="relative block group overflow-hidden md:w-1/2 flex md:hidden" data-aos="fade-down">
                                <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={G2} width="540" height="340" alt="casa" style={{ borderRadius: '10px' }} />
                               

                                </div>
                            {/* Content */}
                            <div className="md:w-1/2 mt-4 md:mt-1" data-aos="fade-right">
                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle2")}</p>
                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle2b")}</p>
                                <p className=" sec-grey-text text14 grow" > <span>
                                    {t("landingCarbon.descriptionSubTitle2")}
                                    </span>
                                    <span className='font-bold pl-1'>
                                    {t("landingCarbon.descriptionSubTitle2b")}
                                    </span></p>

                            </div>
                            <div className='' id='bannerMetodologia'></div>
                            {/* Image */}
                            <div className="relative block group overflow-hidden md:w-1/2 hidden md:flex" data-aos="fade-down">
                                <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={G2} width="540" height="340" alt="casa" style={{ borderRadius: '10px' }} />
                                <div className="absolute top-6 right-6">

                                </div>

                            </div>
                        </article>

                        {/* 3 */}
                        <article className="max-w-sm mx-auto  md:max-w-none md:flex md:items-center gap-5 py-5">
                            {/* Image */}
                            <div className="relative block group overflow-hidden md:w-1/2" data-aos="fade-down">

                                <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={G3} width="540" height="340" alt="amazonas" style={{ borderRadius: '10px' }} />
                                <div className="absolute top-6 right-6">

                                </div>
                            </div>
                            {/* Content */}
                            <div className="md:w-1/2 mt-4 md:mt-1" data-aos="fade-left">

                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle3")}</p>
                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle3b")}</p>
                                <p className=" sec-grey-text text14 grow" >   <span className='font-bold pr-1'>
                                    {t("landingCarbon.descriptionSubTitle3")}.
                                    </span>
                                    <span>
                                    {t("landingCarbon.descriptionSubTitle3b")}
                                    </span></p>

                            </div>
                        </article>

                        {/* 4 */}
                        <article className="max-w-sm mx-auto  md:max-w-none md:flex md:items-center gap-5 py-5">
                                 {/* Image */}
                                 <div className="relative block group overflow-hidden md:w-1/2 flex md:hidden" data-aos="fade-down">
                                <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={G4} width="540" height="340" alt="casa" style={{ borderRadius: '10px' }} />
                               

                                </div>
                            {/* Content */}
                            <div className="md:w-1/2 mt-4 md:mt-1" data-aos="fade-right">


                                <p className=" sec-green-text text16  grow " >{t("landingCarbon.descriptionTitle4")}</p>
                               
                                <p className=" sec-grey-text text14 grow" > <span>
                                    {t("landingCarbon.descriptionSubTitle4")}
                                    </span>
                                    <span className='font-bold pl-1'>
                                    {t("landingCarbon.descriptionSubTitle4b")}
                                    </span></p>

                            </div>

                            {/* Image */}
                            <div className="relative block group overflow-hidden md:w-1/2 hidden md:flex" data-aos="fade-down">
                                <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={G4} width="540" height="340" alt="casa" style={{ borderRadius: '10px' }} />
                                <div className="absolute top-6 right-6">

                                </div>

                            </div>
                        </article>






                    </div>

                </div>



            </section>

        </div>
    )
}
