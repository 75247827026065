import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext"
import { useContext } from 'react'
import calculadora from '../../../../images/iconoCalculadora.png'
import calculadorab from '../../../../images/iconoCalculadoraBlanco.png'
import empresa from '../../../../images/iconoEmpresaBlanco.png'
import grafico from '../../../../images/iconoGraficaBlanco.png'
import { Col } from 'react-bootstrap';

export default function BloqueElegir() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className=' sec-green-background'>


            <section className='max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5' >

                <div className="max-w-6xl mx-auto px-4 sm:px-6 pb-4 pt-5 mt-5">
                    <div className='pt-5 text-center text30 text-white' data-aos="zoom-y-out">
                        <h2 className='px-title' >
                            <span>{t("landingCarbon.titleTxtCuadrado")}</span>
                            <span className='font-bold'> AirCO2</span>
                            <span>{t("landingCarbon.titleTxtCuadradob")}</span>
                        </h2>


                    </div>
                    {/* Items */}
                   


                    <div className="justify-between  mb-5 mt-5 flex flex-wrap"
                        data-aos-id-blocks
                        
                    >
                        {/* 1 item */}
                        <Col xs={12} md={4}
                            className={` flex flex-col text-white pt-5`}


                            data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]"
                        >
                            <div className='m-2'>
                                <img src={calculadorab} width="75" alt="imagen-item" />

                                <h3 className=" mb-2  text16">{t("landingCarbon.contabilidadtitle")}</h3>
                                <p className=" text12">
                                    <span className='font-bold'>
                                        {t("landingCarbon.contabilidadText")}
                                    </span>
                                    <span className=''>
                                        {t("landingCarbon.contabilidadTextb")}
                                    </span>

                                </p>

                            </div>

                        </Col>


                        {/* 2 item */}
                        <Col xs={12} md={4}
                            className={` flex flex-col  text-white pt-5`}

                            data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
                            <div className='m-2'>
                                <img src={empresa} width="75" alt="imagen-item" />
                                <h3 className=" mb-2  text16">{t("landingCarbon.emisionestitle")}</h3>
                                <p className=" text12">
                                    <span className='font-bold'>{t("landingCarbon.emisionesText")}</span>
                                    <span>{t("landingCarbon.emisionesTextb")}</span>
                                </p>

                            </div>

                        </Col>


                        {/* 3rd item */}
                        <Col xs={12} md={4}
                            className={` flex flex-col text-white pt-5`}

                            data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
                            <div className='m-2'>
                                <img src={grafico} width="75" alt="imagen-item" />
                                <h3 className=" mb-2  text16" >{t("landingCarbon.sostenibilidadtitle")}</h3>
                                <p className=" text12">
                                    <span className='font-bold'>{t("landingCarbon.sostenibilidadText")}</span>
                                    <span>{t("landingCarbon.sostenibilidadTextb")}</span>
                                </p>
                            </div>
                        </Col>
                    </div>








                </div>

            </section>


        </div>
    )
}
