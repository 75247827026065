import React from 'react'
import Forestal from './Forestal'
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import { Helmet } from 'react-helmet';

export default function ForestalHome() {
  return (
    <div>
      <Helmet>
        <title>Calcula tu Huella de Carbono con la Calculadora de AirCO2      </title>
        <meta name="description" content="Mide las emisiones de CO2 de tu empresa con la calculadora de huella de carbono certificada de AirCO2. Empieza a reducir tu impacto ambiental hoy mismo con soluciones confiables." />
      </Helmet>
      <Header></Header>
      <Forestal></Forestal>
      <Footer></Footer></div>
  )
}
