import React from 'react'
import Header from '../../../partials/Header'
import Footer from '../../../partials/Footer'

import BloqueBienvenida from './components/BloqueBienvenida'
import BloquePuntos from './components/BloquePuntos'
import BloqueDescrip from './components/BloqueDescrip'
import BloqueDemo from './components/BloqueDemo'
import BloquePlanes from './components/BloquePlanes'
import BloqueNews from './components/BloqueNews'

import BloqueFooter from './components/BloqueFooter'
import BloqueElegir from './components/BloqueElegir'
import BloqueLogos from './components/BloqueLogos'


export default function CarbonManager() {
  return (
    <div>
      <Header>
        <title>Compensa tu Huella de Carbono con Proyectos Certificados | AirCO2</title>
        <meta name="description" content="Compensa las emisiones de carbono de tu empresa a través de proyectos certificados. Con AirCO2, contribuye a un futuro sostenible mientras reduces tu impacto en el planeta." />
      </Header>
      <Header />
      <BloqueBienvenida />
      <BloquePuntos />
      <BloqueLogos />
      <BloqueDescrip />
      <BloqueElegir />
      {/* <BloqueDemo/> */}
      {/* <BloquePlanes/> */}
      <BloqueNews />
      {/* <BloqueMoreRead/> */}
      <BloqueFooter />
      <Footer />
    </div>
  )
}
