import ptmar from '../../../images/success_stories_perez_torres.webp';
import ptmarLogo from '../../../images/pereztorres.png';

import coolberris from '../../../images/success_stories_coolfarming.webp';
import coolberrisLogo from '../../../images/coolfarming2.png';
import coolberrisTetimonial from '../../../images/coolberries.png';

export const clientsData = [
    {
      urlName:"perez-torres-maritima",
      clientName: "Pérez Torres Marítima",
      heroTitle: "clientes.clientsData.ptmar.heroTitle",
      heroDescription: "clientes.clientsData.ptmar.heroDescription",
      heroImageUrl: ptmar,
      logo: ptmarLogo,
      features: [
        { number: "-15%", icon: "check", text: "clientes.clientsData.ptmar.feature1" },
        { number: "+12", icon: "leaf", text: "clientes.clientsData.ptmar.feature2" },
        { number: "-49%", icon: "chart", text: "clientes.clientsData.ptmar.feature3" },
      ],
      actions: [
        "clientes.clientsData.ptmar.action1",
        "clientes.clientsData.ptmar.action2",
        "clientes.clientsData.ptmar.action3",
        "clientes.clientsData.ptmar.action4",
      ],
      problem: "clientes.clientsData.ptmar.problem",
      solution: "clientes.clientsData.ptmar.solution",
      results: "clientes.clientsData.ptmar.results",
      testimonial: "clientes.clientsData.ptmar.testimonial",
      testimonialImg: ptmar,
      testimonialName: ""
    },
    {
        urlName:"cool-berries",
        clientName: "Cool Berries",
        heroTitle: "clientes.clientsData.coolBerris.heroTitle",
        heroDescription: "clientes.clientsData.coolBerris.heroDescription",
        heroImageUrl: coolberris,
        logo: coolberrisLogo,
        features: [
          { number: "-19%", icon: "check", text: "clientes.clientsData.coolBerris.feature1" },
          { number: "+20%", icon: "electricity", text: "clientes.clientsData.coolBerris.feature2" },
          { number: "-36%", icon: "chart", text: "clientes.clientsData.coolBerris.feature3" },
        ],
        actions: [
          "clientes.clientsData.coolBerris.action1",
          "clientes.clientsData.coolBerris.action2",
          "clientes.clientsData.coolBerris.action3",
          "clientes.clientsData.coolBerris.action4",
          "clientes.clientsData.coolBerris.action5",
        ],
        problem: "clientes.clientsData.coolBerris.problem",
        solution: "clientes.clientsData.coolBerris.solution",
        results: "clientes.clientsData.coolBerris.results",
        testimonial: "clientes.clientsData.coolBerris.testimonial",
        testimonialImg: coolberrisTetimonial,
        testimonialName: "Juan Hitos, "
      }
  ];
  
  export default clientsData;
  