import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext";
import { useContext } from 'react'

export default function BloqueFooter() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className="sec-green-background" style={{paddingBottom:'100px'}}>

            <div className="max-w-6xl mx-auto px-4 sm:px-6 pb-5 pt-5"
               >

                <div className='pt-5 pb-5  text-center text-white ' data-aos="zoom-y-out">
                    <h2 className='pt-5 text30'  >{t("landingCarbon.titlefooter")}</h2>
                    <h6 className='px-title pt-5 text16'  >
                      <span>{t("landingCarbon.subTitlefooter")}</span>  
                      <span className='font-bold'>AirCO2</span>  
                      <span>{t("landingCarbon.subTitlefooterb")}</span>  
                        </h6>

                </div>
                <div className='flex justify-center'>
                    <a href="/register">
                        <button className='btn btn-banner-o   lg:w-full  sm:w-auto sm:mb-0 '> {t("landingCarbon.start")} </button>
                    </a>


                </div>
             

             




            </div>
        </div>
    )
}
