import axios from 'axios';
import { React, useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "./../../../context/createcontext";
import UserService from './../../../services/UserService';
import RangeSlider from './RangeSlider';
import { formatThousands } from '../utils/Utils';
import { Container } from 'postcss';

function WelcomeBanner() {

  const [discount, setDiscount] = useState("");

  const [co2Current, setCo2Current] = useState({
    co2CurrentData: [],
    isFetching: false
  });
  useEffect(() => {
    const fetchco2CurrentData = async () => {
      try {
        setCo2Current({ co2CurrentData: co2Current.co2CurrentData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_CO2_CURRENT}`, {
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setCo2Current({ co2CurrentData: response.data, isFetching: false });

      } catch (exception) {
        console.error(exception);
        setCo2Current({ co2CurrentData: co2Current.co2CurrentData, isFetching: false });
      }
    };
    fetchco2CurrentData();
  }, []);


  UserService.getPlan() === ""
    ?
    <>
  

    </>
    :
    useEffect(() => {
      fetch(`${process.env.REACT_APP_PAYMENTS_PLAN}/${UserService.getPlan()}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          },
        }).then((response) => {
          return response.json()
        })
        .then((discount) => {
        
          if (!discount.error) {
            setDiscount(discount)
          }
        })
    }, [])



  // cambiar el idioma
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  return (

    <div className="relative sec-green-background p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block" aria-hidden="true">
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient x1="50%" y1="24.537%" x2="50%" y2="100%" id="welcome-c">
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path fill="url(#welcome-c)" mask="url(#welcome-d)" d="M64-24h80v152H64z" />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path fill="url(#welcome-c)" mask="url(#welcome-f)" d="M40.333-15.147h50v95h-50z" />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path fill="url(#welcome-c)" mask="url(#welcome-h)" d="M40.333-15.147h50v95h-50z" />
            </g>
          </g>
        </svg>
      </div>


      {/* Content */}
      <Row >
        <Col className=' col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 col-xxl-10'>
          <h1 className="text-2xl md:text-3xl  font-bold mb-1"> {t("dash.reduceemisiones")} 🌎</h1>
        </Col>
        <Col className=' col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2'>
          <Row>
            <Col className=' col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-xxl-12  '>
              <div className=' text-xs text-center xl:text-sm text-white xxl:text-sm bg-indigo-500 rounded' >{t("dash.mercado")}:&nbsp;
                {co2Current.co2CurrentData.length === 0 ? 
                <Spinner animation="border" variant="light" size="sm" /> 
                : <span><b>{formatThousands(co2Current.co2CurrentData.price)}</b> €/t</span>}
              </div>
            </Col>

            {UserService.getPlan() === ""
              ?
              <>
              
              </>
              :

              <Col className=' col-6 col-sm-6 mt-md-1 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                <div className=' text-xs text-center xl:text-sm text-white xxl:text-sm bg-indigo-500 rounded'>AirCO2:&nbsp;
                  {discount.length === 0 ?
                    <Spinner animation="border" variant="light" size="sm" />
                    :
                    <span>
                      <b>
                        {(formatThousands(co2Current.co2CurrentData.price - co2Current.co2CurrentData.price * discount.discountCO2))
                        }
                       </b> €/t <small>(-{(discount.discountCO2 * 100)}%)</small>
                      
                    </span>
                  }
                </div>
              </Col>



            }




          </Row>
        </Col>
      </Row>
      <br></br>
      <RangeSlider co2price={co2Current.co2CurrentData.price} discount={discount.discountCO2} />
      <br></br>
      <p className='text-xs'>*{t("dash.pulsarcompensar")}</p>
    </div>
  );
}

export default WelcomeBanner;
