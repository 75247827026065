
import { default as React, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import BarChart from '../../charts/BarChart03Type'
import { formatThousands } from '../../utils/Utils'
import { tailwindConfig } from '../../utils/Utils';
export default function ScopeBarOrganizations({
  calculations
}) {
  // cambiar idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  // const arrayAlcance = [
  //   `${formatThousands(scope1fixed)} ${t("dash.alcance1")}`,
  //   `${formatThousands(scope2fixed)} ${t("dash.alcance2")}`,
  //   `${formatThousands(scope3fixed)} ${t("dash.alcance3")}`
  // ];


  const arrayAlcance = calculations.map(item => ([
    item.totalKgCO2eScope1, item.totalKgCO2eScope2, item.totalKgCO2eScope3, item.annualTurnover
  ]));
  const arrayScopesLabels = [t("dash.alcance1"), t("dash.alcance2"), t("dash.alcance3"), t("footPrint.total")];

  const colores = [{ color: [tailwindConfig().theme.colors.yellow[400]], backgroundColor: [tailwindConfig().theme.colors.yellow[500]] },
  { color: [tailwindConfig().theme.colors.indigo[500]], backgroundColor: [tailwindConfig().theme.colors.indigo[600]] },
  { color: [tailwindConfig().theme.colors.orange[400]], backgroundColor: [tailwindConfig().theme.colors.orange[500]] }]

  function areAllZeros(array) {
    return array.every(element => element === 0);
  }

  const datasets = calculations?.map((calc, index) => {
    return {
      label: calc.organization.name,
      data: [calc.totalKgCO2eScope1, calc.totalKgCO2eScope2, calc.totalKgCO2eScope3, calc.totalKgCO2e],
      backgroundColor: colores[index].color,
      hoverBackgroundColor: colores[index].backgroundColor,
      hoverBorderColor: tailwindConfig().theme.colors.white,
    };
  });

  const chartData = {
    labels: arrayScopesLabels,
    datasets: datasets,
  };

  // const chartDataForButtons = {
  //   labels: arrayAlcance,
  //   datasets: [
  //     {
  //       label: arrayAlcance[0],
  //       data: [scope1],
  //       backgroundColor: [tailwindConfig().theme.colors.yellow[400]],
  //       hoverBackgroundColor: [tailwindConfig().theme.colors.yellow[500]],
  //       hoverBorderColor: tailwindConfig().theme.colors.white,
  //     },
  //     {
  //       label: arrayAlcance[1],
  //       data: [scope2],
  //       backgroundColor: [tailwindConfig().theme.colors.indigo[500]],
  //       hoverBackgroundColor: [tailwindConfig().theme.colors.indigo[600]],
  //       hoverBorderColor: tailwindConfig().theme.colors.white,
  //     },
  //     {
  //       label: arrayAlcance[2],
  //       data: [scope3],
  //       backgroundColor: [tailwindConfig().theme.colors.orange[400]],
  //       hoverBackgroundColor: [tailwindConfig().theme.colors.orange[500]],
  //       hoverBorderColor: tailwindConfig().theme.colors.white,
  //     },
  //   ],
  // };

  useEffect(() => {
    arrayAlcance
  }, [lng]);

  return (
    <div id='galcance' className="flex flex-col col-span-full sm:col-span-6 bg-white  rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{t("footPrint.totalByAlcance")}</h2>
      </header>


      <div className="grow">

        {areAllZeros(arrayAlcance) ? (
          <Spinner className='flex justify-center' />
        ) : (
          <BarChart data={chartData} width={595} height={248} arrayAlcance={arrayAlcance} arrayAlcanceLabels={arrayScopesLabels} />

        )}
      </div>



    </div>
  )
}
