import React from 'react';

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Introducction from './componentsHome/Introducction';

import BloqueIconostxt from './componentsHome/BloqueIconostxt';
import BloqueImgSlide from './componentsHome/BloqueImgSlide';
import BannerCertificaciones from './componentsHome/BannerCertificaciones';
import SuccessStories from './componentsHome/SuccessStories';
import CommunityClub from './componentsHome/CommunityClub';
import BannerForestal from './componentsHome/BannerForestal';
import BannerDemo from './componentsHome/BannerDemo';
import BloqueClientes from './componentsHome/BloqueClientes';
import Nosotros from './Nosotros';
import { Helmet } from 'react-helmet';
export default function Home() {

  return (
    <div id='inicio'>
      <Helmet>
        <title>Calcular Huella de Carbono y Compensación de Emisiones | AirCO2</title>
        <meta name="description" content="En AirCO2 calculamos la huella de carbono de tu empresa con nuestra calculadora certificada y te ayudamos a compensar las emisiones de CO2. Empieza ahora a reducir tu huella de carbono." />
      </Helmet>


      <Header></Header>
      <Introducction></Introducction>

      <BloqueClientes></BloqueClientes>
      <BloqueIconostxt></BloqueIconostxt>
      <BloqueImgSlide></BloqueImgSlide>

      <BannerCertificaciones></BannerCertificaciones>
      <SuccessStories></SuccessStories>
      <CommunityClub></CommunityClub>
      <BannerForestal></BannerForestal>
      <BannerDemo></BannerDemo>

      {/* <PopupMetolodogia></PopupMetolodogia> */}

      {/* 
      <PrincipalBanner></PrincipalBanner>
      <div style={{ minHeight: '100px', background: 'linear-gradient(var(--airco2-green-color) 0%,   #b1e3d3 35%,  #d3ede5 70%, #ffffff 100% )' }} ></div> */}

      {/* <Hometemplate></Hometemplate> */}
      {/* <div style={{ minHeight: '150px', background: 'linear-gradient(#ffffff 0%,   #96ebce 85%, var(--airco2-green-color) 100% )' }} ></div> */}
      {/* <Forestal></Forestal> */}
      {/* <div id='nosotros' style={{ minHeight: '250px', background: 'linear-gradient(var(--airco2-green-color) 0%,  #b1e3d3 55%,  #d3ede5 85%, #ffffff 100% )' }} ></div>
      <Nosotros></Nosotros> */}
      <Footer></Footer>
    </div>
  )
}
