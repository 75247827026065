import React, { useState } from 'react';
import UserMenu from './utils/DropdownProfile';
import Language from './utils/DropdownLanguage';
import { useTranslation } from 'react-i18next';
import PageMetatags from '../../utils/Metatags';
import UserService from '../../services/UserService';
import guide from '../../images/guide-book.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../images/Logo verde_airCO2.png'

function Header({
  sidebarOpen,
  setSidebarOpen,
  triggerTour
}) {

  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const { t } = useTranslation("global");
  const location = useLocation();
  const { pathname } = location;
  const [activeTourButton, setActiveTourButton] = useState((window.location.pathname == "/footprint/carbonmanager" || window.location.pathname == "/footprint/previousdata") ? true : false)
  function handleTourClick() {
    triggerTour(true);
  }

  const renderTooltipPaseo = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("nav.tour")}
    </Tooltip>
  );
  const isFootprintForm = pathname === '/footprint/carbonmanager' || pathname === '/footprint/finalized';

  return (
    <header className="sticky top-0 sec-neutro-background border-b border-gray-200 z-40">
      <PageMetatags title={t("meta.areaInterna.title") + (UserService.getUserType() !== undefined
        ? (UserService.getUserType() === "Representante empresarial" ?
          t("meta.areaInterna.type.empresa") : t("meta.areaInterna.type.forestal"))
        : t("meta.areaInterna.type.admin"))} />

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

          {/* Header: Left side */}
          <div className="flex">

            {/* Hamburger button */}
            <button arial-label="btn-hamburger"
              className={`text-gray-500 hover:text-gray-600 lg:${isFootprintForm ? 'block' : 'hidden'}`}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => { e.stopPropagation(); setSidebarOpen(!sidebarOpen) }}

            >


              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            {/* <button aria-label='inicioAirco2' className={`${isFootprintForm ? 'block' : 'hidden'}`}>
              <Link aria-label='inicio' className="nav-link" to={'/'} > <img height="200" width="150" src={logo}></img></Link>
            </button> */}

          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-2">

            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipPaseo}
            >
              <button className={` rounded-full mr-2  hover:bg-gray-200  ${!activeTourButton && 'hidden'}`} onClick={handleTourClick}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M12 9h.01" />
                  <path d="M11 12h1v4h1" />
                </svg>
              </button>
            </OverlayTrigger>


            <Language align="left" />

            {/*  Divider */}
            <hr className="w-px h-6 bg-gray-200 mx-1" />
            <UserMenu align="right" />

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;