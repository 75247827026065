import axios from "axios";
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import ReductionCommitmentCreateModal from "./ReductionCommitmentCreateModal";
import Tooltip from 'react-bootstrap/Tooltip';


export default function DecarbonizationTable({ resume, items, updateScenarioItem, updatedQuestionLine }) {
    const [t, i18n] = useTranslation("global");

    const [calculationQuesitonEvolutions, setCalculationQuestionEvolutions] = useState({ data: undefined, scopea1and2: undefined, scope3: undefined, isFetching: false });
    const [alcanceActivo, setAlcanceActivo] = useState(1);
    const [reductionCommitmentsList, setReductionCommitmentsList] = useState([]);
    const [newCreatedCommitment, setNewCreatedCommitment] = useState(undefined);
    const [highlightReductionCommitmentList, setHighlightReductionCommitmentList] = useState([]);


    const [showModalCreate, setShowModalCreate] = useState({ view: false, questionId: undefined });
    const handleSetShowModalCreate = (show, question) => {
        setShowModalCreate({ view: show, questionId: question })
    }

    useEffect(() => {
        if (items !== undefined) {
            try {
                const tablaScope1y2 = items.filter(item => item.scope === 1 || item.scope === 2);
                const tablaScope3 = items.filter(item => item.scope === 3);
                setCalculationQuestionEvolutions({ data: items, scopea1and2: tablaScope1y2, scope3: tablaScope3, isFetching: true })
                setHighlightReductionCommitmentList([...highlightReductionCommitmentList, updatedQuestionLine]);
            } catch (exception) {
                setCalculationQuestionEvolutions({ data: undefined, scopea1and2: undefined, scope3: undefined, isFetching: true })
                console.error(exception);
            }
        }
    }, [items]);

    useEffect(() => {
        const fetchCalculationEvolutions = async () => {
            const url = `${process.env.REACT_APP_CH_REDUCTION}`;
            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                setReductionCommitmentsList({ data: response?.data?.data, isFetching: true })
            } catch (exception) {
                setReductionCommitmentsList({ data: undefined, isFetching: false });
                console.error(exception);
            }
        };

        if (!reductionCommitmentsList?.isFetching) {
            fetchCalculationEvolutions();
        }
    }, []);



    const cambiarAlcance = (alcance) => {
        setAlcanceActivo(alcance);
    };

    const handleUpdateScenarioItem = (questionId, reductionCommitmentItem, oldPercent, oldCost) => {
        updateScenarioItem(questionId, reductionCommitmentItem, oldPercent, oldCost);
        setHighlightReductionCommitmentList([...highlightReductionCommitmentList, questionId]);
    }

    const handleAddReductionCommitment = (commitment) => {
        setReductionCommitmentsList(prevState => ({
            ...prevState,
            data: [...prevState.data, commitment],
            isFetching: true
        }));
        setNewCreatedCommitment(commitment.id)
    }

    const updateSelectedReductionCommitmentList = async (commitmentsQuestionsList, old) => {
        let questionsToHighight = [];
        let newItemsToUpdate = [];

        const itemsActualizados = items.map(item => {
            // Buscar si hay un objeto en commitmentsQuestionsList con el mismo questionId
            const actualizacion = commitmentsQuestionsList.find(
                (compromiso) => compromiso.questionId === item.questionId
            );

            // Si hay una coincidencia, actualizar decarbonizationScenarioItemOutputDtos
            if (actualizacion) {
                let newItem = item;
                let oldCost = item.decarbonizationScenarioItemOutputDtos?.reductionCommitments?.reduce(
                    (acumulador, elemento) => {
                        return elemento.hasOwnProperty("averageImplementationCost")
                            ? acumulador + elemento.averageImplementationCost
                            : acumulador;
                    },
                    0
                );
                newItem.decarbonizationScenarioItemOutputDtos = actualizacion;
                questionsToHighight.push(item.questionId);
                handleUpdateScenarioItem(newItem.questionId, newItem?.decarbonizationScenarioItemOutputDtos, undefined, oldCost);
            }
        });
        setHighlightReductionCommitmentList(questionsToHighight);
    }



    return (
        <React.Fragment>
            <ReductionCommitmentCreateModal
                showModal={showModalCreate}
                hanldeShow={handleSetShowModalCreate}
                handleAddReductionCommitment={handleAddReductionCommitment} />

            {!calculationQuesitonEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {calculationQuesitonEvolutions?.isFetching && <>
                <div className="mb-3">
                    <ul className="flex flex-wrap -m-1">
                        <li className="m-1">
                            <button onClick={() => cambiarAlcance(1)} className={`${alcanceActivo === 1 ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                {t("decarbonization.scopes1and2")}
                                <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 1 ? 'bg-white' : ' sec-green-background text-white'}`}>
                                    {calculationQuesitonEvolutions?.scopea1and2?.length}
                                </span>
                            </button>
                        </li>
                        <li className="m-1">
                            <button onClick={() => cambiarAlcance(3)} className={`${alcanceActivo === 3 ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                {t("decarbonization.scope3")}
                                <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 3 ? 'bg-white' : ' sec-green-background text-white'}`}>
                                    {calculationQuesitonEvolutions?.scope3?.length}
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="container-simple-table">
                    <table className="simple-table">
                        <thead className="">
                            <tr>
                                <th> <div className="text-left">{t("decarbonization.source")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.percentToReduce")}</div></th>
                                <th> <div className="text-left">{t("decarbonization.reductionList")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.reductionCost")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.reductionPercent")}</div></th>
                                <th> <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip"  >
                                        {t("decarbonization.baseYear")}
                                    </Tooltip>}>
                                    <div className="text-right">{resume.baseYear}</div>
                                </OverlayTrigger></th>
                                <th>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip"  >
                                            {t("decarbonization.currentYear")}
                                        </Tooltip>}>
                                        <div className="text-right"> {new Date().getFullYear()}</div>
                                    </OverlayTrigger></th>
                                <th> <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip"  >
                                        {t("decarbonization.targetYear")}
                                    </Tooltip>}><div className="text-right">{resume.targetYear} </div>
                                </OverlayTrigger>
                                </th>
                                <th> <div className="text-right">{new Date().getFullYear()} vs. {resume.targetYear}</div></th>
                                <th> <div className="text-right">{new Date().getFullYear()}% vs. {resume.targetYear}%</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {alcanceActivo === 1 &&
                                calculationQuesitonEvolutions?.scopea1and2?.map((item) => <FilaTabla
                                    key={item.questionId}
                                    item={item}
                                    scope={alcanceActivo}
                                    scenarioId={resume.id}
                                    reductionCommitmentsList={reductionCommitmentsList}
                                    handleUpdateScenarioItem={handleUpdateScenarioItem}
                                    handleSetShowModalCreate={handleSetShowModalCreate}
                                    newCreatedCommitment={newCreatedCommitment}
                                    updateSelectedReductionCommitmentList={updateSelectedReductionCommitmentList}
                                    updateReductionCommitmentHighLights={highlightReductionCommitmentList.includes(item.questionId)} />)}
                            {alcanceActivo === 3 &&
                                calculationQuesitonEvolutions?.scope3?.map((item) => <FilaTabla
                                    key={item.questionId}
                                    item={item}
                                    scope={alcanceActivo}
                                    scenarioId={resume.id}
                                    reductionCommitmentsList={reductionCommitmentsList}
                                    handleUpdateScenarioItem={handleUpdateScenarioItem}
                                    handleSetShowModalCreate={handleSetShowModalCreate}
                                    newCreatedCommitment={newCreatedCommitment}
                                    updateSelectedReductionCommitmentList={updateSelectedReductionCommitmentList}
                                    updateReductionCommitmentHighLights={highlightReductionCommitmentList.includes(item.questionId)} />)}
                        </tbody>
                    </table>
                </div></>}
        </React.Fragment>
    );


}

function FilaTabla({ item, scope, scenarioId, reductionCommitmentsList, handleUpdateScenarioItem, handleSetShowModalCreate, newCreatedCommitment, updateSelectedReductionCommitmentList, updateReductionCommitmentHighLights }) {

    const [showEdit, setShowEdit] = useState(false);
    const [reductionCommitmentPercent, setReductionCommitmentPercent] = useState(0);
    const [itemReductionCommitmentList, setItemReductionCommitmentList] = useState({});
    const [targetCO2e, setTargetCO2e] = useState(0);
    const [currentVsTarget, setCurrentVsTarget] = useState(0);
    const [currentVsTargetPercent, setCurrentVsTargetPercent] = useState(0);
    const [highlightPercent, setHighlightPercent] = useState(false);
    const [highlightReductionCommitment, setHighlightReductionCommitment] = useState(false);
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        if (item !== undefined) {
            setReductionCommitmentPercent(item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje);
            setItemReductionCommitmentList(item?.decarbonizationScenarioItemOutputDtos?.reductionCommitments || []);
            let target = item.totalKgCO2e - (item.totalKgCO2e * (item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje / 100));
            setTargetCO2e(target);

            if (item.currentKgCO2e && target) {
                setCurrentVsTarget(item.currentKgCO2e - target);
                setCurrentVsTargetPercent(((item.currentKgCO2e - target) / target) * 100);
            }
            if (updateReductionCommitmentHighLights) {
                setHighlightReductionCommitment(true);
            }
        }
    }, [item, updateReductionCommitmentHighLights]);


    const updateSelectedReductionCommitmentListIntermediate = (commitmentsList) => {
        updateSelectedReductionCommitmentList(commitmentsList)
    }


    const [isSaving, setIsSaving] = useState(false)
    const addReductionCommitmentpercent = () => {
        if (reductionCommitmentPercent !== undefined) {
            setIsSaving(true)
            const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${scenarioId}/target`;
            const oldPercent = item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje;
            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${UserService.getToken()}`,
            };
            let data = {
                id: item?.decarbonizationScenarioItemOutputDtos?.id,
                discountPercentaje: reductionCommitmentPercent,
                questionId: item.questionId,
                decarbonizationScenarioId: scenarioId
            };

            try {
                let responsePromise;
                if (data.id !== undefined) {
                    responsePromise = axios.patch(`${url}`, data, { headers });
                } else {
                    responsePromise = axios.post(`${url}`, data, { headers });
                }

                responsePromise.then(response => {
                    handleUpdateScenarioItem(item.questionId, response.data.data, oldPercent, undefined);
                    setReductionCommitmentPercent(undefined);
                    setHighlightPercent(true);
                    setShowEdit(false);
                }).catch(exception => {
                    console.error(exception);
                }).finally(() => {
                    setIsSaving(false);
                });
            } catch (exception) {
                console.error(exception);
            }
        }
    }


    useEffect(() => {
        if (highlightPercent) {
            const timer = setTimeout(() => {
                setHighlightPercent(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [highlightPercent]);


    useEffect(() => {
        if (highlightReductionCommitment) {
            const timer = setTimeout(() => {
                setHighlightReductionCommitment(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [highlightReductionCommitment]);


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addReductionCommitmentpercent();
        }
    };

    const renderTooltipText = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {t("decarbonization.relatedReductionCommitment")}
        </Tooltip>
    );


    const renderTooltipTextAdd = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {t("decarbonization.addReduction")}
        </Tooltip>
    );
    
    return <tr key={item.questionId} >
        <td className="text-left">
            {item.questionTitle}{scope !== 3 &&
                <><br /><span className="ml-2 text-xs text-airco2-general">{t("dash.alcance")} {item.scope}</span></>}
        </td>
        <td className="text-right">
            <div className="flex items-center justify-end space-x-2">
                {showEdit && <>
                    <div className="relative">
                        <input value={reductionCommitmentPercent}
                            className="text-sm pr-8" type="number" step={"0.01"} min={0.01} max={100}
                            onChange={(e) => setReductionCommitmentPercent(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)} />
                        <div className="absolute inset-0 left-auto flex items-center justify-end pointer-events-none">
                            <span className="text-sm text-gray-400 font-medium px-3">%</span>
                        </div>
                    </div>

                    {!isSaving && <>
                        <button onClick={() => addReductionCommitmentpercent()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                <path d="M14 4l0 4l-6 0l0 -4" />
                            </svg>
                        </button>
                        <button onClick={() => setShowEdit(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M18 6l-12 12" />
                                <path d="M6 6l12 12" />
                            </svg>
                        </button>
                    </>}
                    {isSaving && <div className="text-center"><Spinner className='' /></div>}
                </>}
                {!showEdit &&
                    <>{item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje ?
                        <> {formatThousands(item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje)}
                            <span className="text-xs">%</span>
                            <button onClick={() => setShowEdit(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                    <path d="M16 5l3 3" />
                                </svg>
                            </button></>
                        :
                        <>
                            {!reductionCommitmentPercent && itemReductionCommitmentList && itemReductionCommitmentList.length > 0 &&
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipText}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f19b7f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 9v4" />
                                            <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                            <path d="M12 16h.01" />
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            }
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipTextAdd}>
                                <button className=" text-xs text-nowrap	" onClick={() => setShowEdit(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playlist-add" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M19 8h-14" />
                                        <path d="M5 12h9" />
                                        <path d="M11 16h-6" />
                                        <path d="M15 16h6" />
                                        <path d="M18 13v6" />
                                    </svg>
                                </button>
                            </OverlayTrigger>
                        </>
                    }

                    </>
                }

            </div>
        </td>
        <td className="text-left">{item?.decarbonizationScenarioItemOutputDtos &&
            <ReductionCommitmentsBlocks
                reductionCommitments={itemReductionCommitmentList}
                scenarioId={scenarioId}
                targetId={item?.decarbonizationScenarioItemOutputDtos?.id}
                discountPercentaje={item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje}
                reductionCommitmentsList={reductionCommitmentsList}
                questionId={item.questionId}
                handleSetShowModalCreate={handleSetShowModalCreate}
                newCommitment={newCreatedCommitment}
                updateSelectedReductionCommitmentListIntermediate={updateSelectedReductionCommitmentListIntermediate}>
            </ReductionCommitmentsBlocks>}

        </td>

        <td className={`text-right ${(highlightReductionCommitment && itemReductionCommitmentList?.length > 0) ? 'highlight' : ''}`}>
            {itemReductionCommitmentList?.length > 0 ? (
                <>
                    {itemReductionCommitmentList.reduce(
                        (acumulador, elemento) => {
                            return elemento.hasOwnProperty("averageImplementationCost")
                                ? acumulador + elemento.averageImplementationCost
                                : acumulador;
                        },
                        0
                    )}
                    <span className="text-xs">€</span>
                </>
            ) : null}
        </td>
        <td className={`text-right ${(highlightReductionCommitment && itemReductionCommitmentList?.length > 0) ? 'highlight' : ''}`}>
            {itemReductionCommitmentList?.length > 0 ? (
                <>
                    {itemReductionCommitmentList.reduce(
                        (acumulador, elemento) => {
                            return elemento.hasOwnProperty("averagePercentageReduction")
                                ? acumulador + elemento.averagePercentageReduction
                                : acumulador;
                        },
                        0
                    )}
                    <span className="text-xs">%</span>
                </>
            ) : null}
        </td>


        <td className="text-right">{item.totalKgCO2e ? <>{formatThousands(item.totalKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{item.currentKgCO2e ? <>{formatThousands(item.currentKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className={`text-right ${highlightPercent ? 'highlight' : ''}`}>{targetCO2e ? <>{formatThousands(targetCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className={`text-right ${highlightPercent ? 'highlight' : ''}`}>{currentVsTarget ? <span className={`${currentVsTarget < 0 ? "down" : "up"}`}>{formatThousands(currentVsTarget)}<span className="text-xs">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className={`text-right ${highlightPercent ? 'highlight' : ''}`}>{currentVsTargetPercent ? <span className={`${currentVsTargetPercent < 0 ? "down" : "up"}`}>{formatThousands(currentVsTargetPercent)}<span className="text-xs">%</span></span> : "-"} </td>

    </tr >
}


const ReductionCommitmentsBlocks = ({ reductionCommitments, reductionCommitmentsList, scenarioId, targetId, discountPercentaje, questionId, handleSetShowModalCreate, newCommitment, updateSelectedReductionCommitmentListIntermediate }) => {
    const [chips, setChips] = useState([]);
    const [inputValue, setInputValue] = useState(undefined);
    const [t, i18n] = useTranslation("global");
    const [showAdd, setShowAdd] = useState(false);
    const [addingCommitment, setAddingCommitment] = useState(false);
    const [deletingingCommitment, setDeletingingCommitment] = useState({ id: 0, active: false });

    useEffect(() => {
        if (reductionCommitments !== undefined && reductionCommitments.length > 0) {
            setChips(reductionCommitments)
        } else {
            setChips([])
        }
    }, [reductionCommitments]);

    useEffect(() => {
        if (newCommitment !== undefined) {
            setInputValue(newCommitment)
        }
    }, [newCommitment]);


    const addChip = async () => {
        setAddingCommitment(true);
        const objetosFiltrados = reductionCommitmentsList.data.filter(objeto => inputValue == (objeto.id));
        let questions = objetosFiltrados[0].reductionCommitmentQuestions.map(question => question.questionName);
        if (window.confirm(t("decarbonization.commitmenRelatedQuestion") + '' + questions.join(', ') + '. ' + t("decarbonization.addCommitment"))) {
            const response = await addReductionCommitment(inputValue);
            if (response) {
                updateSelectedReductionCommitmentListIntermediate(response.data.data);
                setInputValue(undefined);
            }
        }
        setAddingCommitment(false);
    };

    const removeChip = async (index, id) => {
        setDeletingingCommitment({ id: id, active: true })
        const response = await deleteReductionCommitment(id)
        if (response) {
            updateSelectedReductionCommitmentListIntermediate(response.data.data);
        }
        setDeletingingCommitment({ id: 0, active: false });
    };

    const deleteReductionCommitment = async (reductionCommitmentId) => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${scenarioId}/target/${targetId}/reduction-commitment/${reductionCommitmentId}`;
        try {
            const response = await axios.delete(`${url}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            return response;
        } catch (exception) {
            console.error(exception);
            return false;
        }
    }

    const addReductionCommitment = async (reductionCommitmentId) => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${scenarioId}/target`;
        let data = {
            reductionCommitmentsids: [reductionCommitmentId],
            id: targetId,
            questionId: questionId
        }
        try {
            const response = await axios.patch(`${url}`, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            return response;
        } catch (exception) {
            console.error(exception);
            return false;
        }
    }

    const filterUsedItems = (originalList, usedList) => {
        const usedIds = new Set(usedList.map(item => item.id));
        return originalList.filter(item => !usedIds.has(item.id));
    };


    const renderTooltipTextShow = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {t("decarbonization.showNewReductionCommitments")}
        </Tooltip>
    );

    const renderTooltipTextHide = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {t("decarbonization.hideNewReductionCommitments")}
        </Tooltip>
    );

    const renderTooltipTextAdd = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {t("decarbonization.addReductionCommitment")}
        </Tooltip>
    );
    return (
        <div>
            <div className="flex flex-wrap gap-1 ">
                {chips?.map((chip, index) => (
                    <div
                        key={index}
                        className="chip flex items-center sec-green-background text-white text-xs px-2 py-1  space-x-2 rounded-full"
                        title={chip.name}
                    >
                        {chip.name.length > 20 ? `${chip.name.slice(0, 20)}...` : chip.name}
                        {!deletingingCommitment.active && <button
                            className="text-sm text-red-500 hover:text-red-700"
                            onClick={() => removeChip(index, chip.id)}
                        >
                            &times;
                        </button>}
                        {deletingingCommitment.active && deletingingCommitment.id === chip.id && <div className="text-center"><Spinner className='w-6 h-6' /></div>}
                    </div>
                ))}
            </div>
            {discountPercentaje &&
                <div className="flex flex-wrap items-center justify-center space-x-2">
                    <div>
                        {!showAdd &&
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipTextShow}>
                                <button className="text-xs flex items-center" onClick={() => setShowAdd(!showAdd)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                        <path d="M9 12h6" />
                                        <path d="M12 9v6" />
                                    </svg>
                                </button>
                            </OverlayTrigger>
                        }
                    </div>
                    {showAdd && <div className="mt-2 flex">
                        <select
                            className="w-full text-sm"
                            type="text"
                            value={inputValue}
                            onChange={(e) => e.target.value === "nuevo" ? handleSetShowModalCreate(true, questionId) : setInputValue(e.target.value)}
                        >
                            <option value="">{t("decarbonization.selectReductionCommitment")}</option>
                            <option value="nuevo" className="font-bold"> {t("decarbonization.createReductionCommitment")}</option>

                            {filterUsedItems(reductionCommitmentsList?.data, chips).filter(item => item.reductionCommitmentQuestions.some(question =>
                                question.questionId === questionId
                            ) || !item.reductionCommitmentQuestions
                                || item.reductionCommitmentQuestions?.length === 0).map(x =>
                                    <option key={x.id} value={x.id}>
                                        {x.name.charAt(0).toUpperCase() + x.name.slice(1).toLowerCase()}
                                    </option>)}

                        </select>
                        {!addingCommitment && <>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipTextAdd}>
                                <button
                                    className=""
                                    onClick={addChip}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                        <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        <path d="M14 4l0 4l-6 0l0 -4" />
                                    </svg>
                                </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipTextAdd}>
                                <button
                                    className=""
                                    onClick={() => setShowAdd(!showAdd)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M18 6l-12 12" />
                                        <path d="M6 6l12 12" />
                                    </svg>
                                </button>
                            </OverlayTrigger>
                        </>
                        }
                        {addingCommitment && <div className="text-center"><Spinner className='' /></div>}
                    </div>
                    }
                </div>
            }
        </div >
    );
};
