import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../../context/createcontext";
import combustible from '../../../../../images/car2.svg';
import { formatThousands } from '../../../utils/Utils';
import { Spinner } from 'reactstrap';

export default function Combustible({ highLights, formatNumber }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [combustibleConsumption, setCombustibleConsumption] = useState();
    const [kgCombustible, setKgCombustible] = useState();

    useEffect(() => {
        setCombustibleConsumption(highLights.data?.combustibleConsumption);
        setKgCombustible(formatNumber(highLights.data?.combustibleConsumption?.kgCO2e)); 
    }, [highLights])




    return (
        <div className='grid-report-hightlights rounded-sm border border-gray-200 px-2 py-3' >

            <div className='flex flex-col text-center items-center justify-center'>
                <img src={combustible} className="" />
            </div>

            <div className='flex flex-col text-center items-center justify-center'>
                {t("footPrint.combustible")}<br />
                {t("dash.alcance1")}
            </div>

            <div className='flex flex-col justify-center'>


                {!highLights.isFetching && <div className='text-center'>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />
                </div>}



                {highLights.isFetching && <>

                    {combustibleConsumption &&
                        <div className='text-center mt-1 text-md font-bold mb-4'>
                            {combustibleConsumption.name}
                        </div>
                    }

                    {(kgCombustible && combustibleConsumption) &&
                        <div className='text-center w-full flex flex-col md:flex-row justify-evenly text-md'>
                            <div className='w-full'><span className='font-bold text-xl'> {formatThousands(kgCombustible)}</span><span className='text-xs '> kgCO<sub>2</sub>e</span></div>
                            <div className='w-full'><span className='font-bold text-xl'> {formatThousands(combustibleConsumption.quantity)}</span><span className='text-xs '>{t("footPrint.uds")}</span></div>
                        </div>
                    }
                    {(kgCombustible === undefined && combustibleConsumption === undefined) &&
                        <div className='text-center w-full flex flex-col md:flex-row justify-evenly text-md'>
                            <div className='text-xs'> {t("footPrint.sinDatoSombustible")}</div>
                        </div>
                    }
                </>

                }




            </div>






        </ div>
    )
}