import React from 'react'
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import calendario from '../../../images/calendario.svg'
import dossier from '../../../images/Dossier_Proyecto.webp'
import dossierSvg from '../../../images/dossierAzul.svg'

export default function BloqueForm() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobilephone, setPhone] = useState("");

    const [consent, setConsent] = useState(false);

    const [hubspotResponse, setHubspotResponse] = useState("");
    const [formError, setFormError] = useState("");
    const [text, setText] = useState("")
    const url = 'https://links.bastidafarina.com/widget/bookings/reunion-30-source-web-airco2'

    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!name || !lastName || !email || !mobilephone) {
            handleError("Debes cubrir todos los campos obligatorios.")
            return;


        }

        if (!consent) {
            handleError("Debes marcar la casilla que nos permita almacenar tus datos personales.")
            return;

        }


        setHubspotResponse(await submit_landing_hubspot_form(name, lastName, email, mobilephone));
    }

    const handleOnchange = () => {
        setFormError("");

    }


    const handleError = (mensaje) => {
        document.getElementById('main')?.scrollIntoView({ behavior: 'smooth' });
        setFormError(mensaje);
    }

    const submit_landing_hubspot_form = async (name, lastName, email, mobilephone) => {
        const portalId = '25435066';
        const formGuid = 'c273f8a1-5995-4b1c-a23b-fb09b33f8439';
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
        const data = {
            fields: [

                {
                    name: 'firstname',
                    value: name
                },
                {
                    name: 'lastname',
                    value: lastName
                },
                {
                    name: 'email',
                    value: email
                }
                ,
                {
                    name: 'mobilephone',
                    value: mobilephone
                }




            ]
        };



        return fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                setText('Gracias, tu solicitud ha sido registrada. Nos pondremos en contacto contigo lo antes posible')
                return responseJson;


            });

    }
    return (
        <div className=''>
            <div className='bloqueIcono-Txt text-airco2-concellos  sec-green-background flex  p-5 justify-center gap-4 flex-wrap'>

                <div className='flex items-end'>

                    <p className='text-2xl text-airco2-subtitle-concellos text-center'>
                        <span className=' '>  {(t("landingConcellos.delantera"))}</span>

                        <span className='font-bold'>  {(t("landingConcellos.agendaReu"))}</span>

                    </p>
                </div>

                <div className='flex flex-col items-center' id='dossierRef' >
                    <img width={84} src={calendario} alt="calendario" />
                    <a aria-label='redirige a calendario ' target='_blank' href={url}>
                        <button className=' btn sec-blue-background text-white'>
                            {(t("landingConcellos.agendaReunion"))}
                        </button>

                    </a>

                </div>

            </div>

            <div className='container-bottom flex flex-wrap text-airco2-concellos'>

                <div className='w-full md:w-1/2 mt-2   p-4 flex flex-col'>

                    <p className='text-xl text-center'>
                        {(t("landingConcellos.solicita"))}
                        <span className='font-bold'>{(t("landingConcellos.ahora"))}</span>
                        {(t("landingConcellos.nuestroDossier"))}
                        <span className='font-bold'>{(t("landingConcellos.gratuita"))}</span>


                    </p>

                    <div className='flex items-center justify-center mt-4'>
                        <p className='text-2xl text-airco2-subtitle-concellos font-extraBold'>
                            {(t("landingConcellos.formulario"))}
                        </p>
                        <img width={83} src={dossierSvg}></img>

                    </div>
                    <div className='text-airco2-concello p-3 flex justify-center mt-4'
                        data-aos="fade-up"
                        data-aos-delay="400"

                    >
                        {(hubspotResponse === "") &&

                            <form className='' id="concellos-form" onSubmit={handleSubmit}>

                                <div className='flex flex-wrap NameLastname gap-3'>
                                    <div className='flex-grow pr-1 mt-1 mb-1'>
                                        <label className="block text-sm font-medium mb-1" htmlFor="name">Nombre <span className="text-red-500">*</span></label>
                                        <input id="firstname" name="firstname" className="w-full" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value), handleOnchange() }} />
                                    </div>
                                    <div className="flex-grow pr-1 mt-1 mb-1">
                                        <label className="block text-sm font-medium mb-1 " htmlFor="lastName">Apellidos <span className="text-red-500">*</span></label>
                                        <input id="lastname" name="lastname" className=" w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setLastName(e.target.value), handleOnchange() }} />
                                    </div>
                                </div>

                                <div className='flex flex-wrap phoneEmail  '>
                                    <div className='w-full  pr-1 mt-4 mb-1 '>
                                        <label className="block text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-500">*</span></label>
                                        <input id="email" name="email" className=" w-full bg-white " type="email" defaultValue={''} required onChange={(e) => { setEmail(e.target.value), handleOnchange() }} />
                                    </div>
                                    <div className='w-full pr-1 mt-4 mb-1'>
                                        <label className="block text-sm font-medium mb-1" htmlFor="phone">Teléfono<span className="text-red-500">*</span></label>
                                        <input id="phone" name="mobilephone" className=" w-full bg-white " type="tel" pattern="[0-9]{7}|[0-9]{9}" defaultValue={''} required onChange={(e) => { setPhone(e.target.value), handleOnchange() }} />
                                    </div>
                                </div>



                                <div>
                                    <label className="flex items-center font-medium mt-4">
                                        <input type="checkbox" className="form-checkbox" required onChange={e => { setConsent(e.target.checked), handleOnchange() }} />
                                        <span className="text-sm ml-2">He leído y acepto las <a target='_blank' href="/privacidadYcookies">condiciones legales y política de privacidad</a><span className="text-red-500" >*</span></span>
                                    </label>
                                </div>

                                <div className=" mt-6">
                                    <button className="btn btn-banner-o  whitespace-nowrap w-full" type="submit" >{(t("landingConcellos.solicitaDossier"))}

                                    </button>


                                </div>
                            </form>

                        }

                        {(hubspotResponse !== "") &&

                            <div className='rounded-lg registro container pc-form w-full md:w-1/2 flex justify-center' style={{ minHeight: '300px' }} >
                                <div className='container-form flex flex-col items-center justify-center rounded-lg h-full w-full'>
                                    <p className='font-bold text-lg text-center pb-2 font-bold'>{text}</p>
                                    {/* <button className="btn sec-green-background text-white w-1/2" onClick={() => (window.location.href = "/")} >{(t("landingEventos.visitaWeb"))}

                                    </button> */}



                                </div>


                            </div>




                        }


                    </div>

                </div>

                <div className='w-full md:w-1/2'
                    style={{
                        backgroundImage: `url(${dossier})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        minHeight: '300px',


                    }}>

                </div>

            </div>



        </div>
    )
}
