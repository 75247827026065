import React from "react";
import imgAllBags from '../../../images/img-bolsas-great-packaging.svg';
import video from '../../../images/limpios.mp4'
import ImageSlider from "./imageSlider";
import TableGreatPackaging from "./table";
import cert1 from '../../../images/gp-BLUEENGEL_GPaz.png';
import cert2 from '../../../images/gp-carbon-footprint-calculated.png';
import cert3 from '../../../images/gp-carbon-footprint-calculated-1.png';
import cert4 from '../../../images/gp-carbon-footprint-calculated-2.png';

export default function landingGreatPackaging() {
  return (

    <main className="font-gp pt-4 container-great-packaging xl:mx-8">

      <div className="container">

        <div className="py-5">
          <div className="row  flex  gp-container-bolsas">
            <div className="col-12 col-md-6 flex " data-aos="fade-up-right">
              <div style={{ height: '100%' }}>
                <ImageSlider style={{ height: '100%', objectFit: 'cover' }} />
              </div>
            </div>
            <div className="col-12 col-md-6 flex flex-col  justify-center" data-aos="fade-up-left">
              <h2 className="text-3xl  mb-4 text-gp-green uppercase text-center">
                Bolsa de asa lazo
              </h2>
              <div className="text-justify  text-xl-gp text-gp-lightgrey">
                <p>
                  Estas bolsas están fabricadas con al menos un 70% de plástico reciclado post consumo, el cual proviene de residuos generados después de que el producto original haya sido utilizado.
                </p>
                <p className="mb-3">
                  La huella de carbono resultado de la producción, transporte y distribución de estas bolsas ha sido compensada.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <h2 className="text-3xl  mb-4 text-gp-green text-center" data-aos="fade-up-top">
            ¿Cómo se producen estas bolsas?
          </h2>
          <div className="row align-items-center">
            <div className="col-12 col-md-6" data-aos="fade-up-right">
              <div className="text-justify  text-xl-gp text-gp-lightgrey">
                <p>
                  Primero, se recolectan productos plásticos ya utilizados y se clasifican según su tipología. Después, se lavan y se trituran para a continuación fundirse en pequeños fragmentos llamados granza. La granza se introducirá en una máquina extrusora que, mediante un sistema de soplado y control de temperatura, generará una lámina de plástico reciclado. Esta lámina se imprimirá y cortará para dar lugar a la nueva bolsa.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6" data-aos="fade-up-left">
              <div className="gp-video-container">
                <iframe className="gp-video"
                  src="https://www.youtube.com/embed/Qa8bprwOAJ8?si=HYIaR6VAaPdERt9B" title="¿Cómo se producen estas bolsas?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>



        <div className="py-5">
          <h2
            className="text-3xl  mb-4 text-gp-green  text-center  " data-aos="fade-up-top">
            Compensación de la huella de carbono
          </h2>
          <div className="text-justify  text-xl-gp text-gp-lightgrey" data-aos="zoom-in">
            <p className="mb-3">
              Estamos comprometidos con el cuidado del medio ambiente. Como parte de este
              compromiso, controlamos, reducimos y compensamos nuestra huella de carbono en todos
              nuestros procesos. Estas bolsas de plástico 100% reciclado tienen trackeado su ciclo de
              producción. A continuación, puedes ver todas las compensaciones de CO2 asociadas a cada
              etapa de su ciclo.</p>
            <p className="mb-3">
              La huella de carbono resultado de la producción, transporte y distribución de estas bolsas ha
              sido compensada en un proyecto de acción climática resultando en un impacto neutro en
              emisiones.
            </p>
            <p className="mb-3">
              Estas bolsas están fabricadas con al menos un 70% de plástico reciclado post consumo, el cual
              proviene de residuos generados después de que el producto original haya sido utilizado.
            </p>
          </div>

          <div className="flex flex-col justify-center h-100 mx-2 md:m-0 " data-aos="zoom-in">
            <img
              src={imgAllBags}
              alt="img-bags"
            />
          </div>

          <div className=" m-2 md:m-0 " id="tableBottom" data-aos="zoom-in">
            <TableGreatPackaging />
            <p className="text-md">
              Cada fila de la tabla representa la producción de un lote de
              bolsas de plástico por GreatPackaging, en colaboración con
              AirCO2, quien realiza el seguimiento de las prácticas
              sostenibles.
            </p>
          </div>
        </div>


        <div className="py-5 ">
          <div className="certification-container" data-aos="fade-up-top">
            <h2 classname="certification-heading">Certificaciones</h2>
          </div>
          <div className="flex items-center justify-center md:gap-4">
            <div data-aos="flip-left" className="gp-image-cert"> <img src={cert1} alt="Blauner Engel"  ></img></div>
            <div data-aos="flip-left" className="gp-image-cert"><img src={cert2} alt="Applus"  ></img></div>
            <div data-aos="flip-left" className="gp-image-cert"><img src={cert3} alt="Recycled UNE"  ></img></div>
            <div data-aos="flip-left" className="gp-image-cert"><img src={cert4} alt="Recycled UNE"  ></img></div>
          </div>
        </div>

        <div className="py-5" style={{ marginTop: '150px' }}></div>

      </div >
    </main >
  );
}
