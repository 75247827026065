import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from "../../../../services/UserService";

import axios from "axios";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import { Modal, Button, Col } from "react-bootstrap";
import Banner from '../../../../components/utils/Banner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatThousands } from '../../utils/Utils';


export default function DecarbonizationScenario({ createScenario }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const [resumeScenario, setResumeScenario] = useState({ data: undefined, isFetching: false });
    const [showEdit, setShowEdit] = useState(false)
    const [name, setName] = useState()
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [scenarioId, setScenarioId] = useState()
    const [sendingRequest, setSendingRequest] = useState(false);

    const fetchCalculationEvolutions = async () => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}s`;

        try {
            const response = await axios.get(`${url}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });

            setResumeScenario({ data: response?.data?.data, isFetching: true })


        } catch (exception) {
            setResumeScenario({ data: undefined, isFetching: false });

            console.error(exception);
        }
    };


    useEffect(() => {

        fetchCalculationEvolutions();
    }, [createScenario])

    const [msg, setMsg] = useState({
        type: '',
        message: '',
        open: false,
    });
    const handleShowEdit = (x) => {
        setShowEdit(true)
        setName(x.name)

        setScenarioId(x.id)
    }

    const handleClose = () => {
        setShowEdit(false)
        setButtonDisabled(false)
        setName()
        setScenarioId()
        cleanMessage()

    }

    const cleanMessage = () => {
        setMsg({
            type: '',
            message: '',
            open: false
        })
    }

    const handleSubmitPut = async (e) => {
        e.preventDefault()
        setSendingRequest(true);
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}`;

        const data = {
            id: scenarioId,
            name: name,

        };

        try {
            const response = await axios.patch(url, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            })

            if (response.status !== 200) {
                throw new Error('Network response was not ok');

            }

            await fetchCalculationEvolutions();




            if (response.status === 200) {
                setButtonDisabled(true);
                setMsg({ type: "success", message: t("fpThird.update-ok") });
            } else {
                setMsg({ type: "error", message: t("fpThird.update-ko") });
            }





        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.update-ko") });
        } finally {
            setSendingRequest(false);
        }
    };

    const renderTooltipEditar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.editar")}
        </Tooltip>
    );

    const renderTooltipVerDetalle = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.verDetalle")}
        </Tooltip>
    );


    return (



        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">




            <Modal show={showEdit}>

                <Modal.Header className="sec-green-background">
                    <Modal.Title className="text-white"></Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => handleClose()}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {msg.type !== "" && <Banner type={msg.type} open setOpen={true}>{msg.message}</Banner>}
                    <Form className='text-xs'
                        onSubmit={(e) => { handleSubmitPut(e) }}

                    >
                        <Col col="6">
                            <FormGroup>
                                <Label for="name">{t("decarbonization.scenarioName")}</Label>
                                <Input className='text-xs' type="text" name="name" id="name" defaultValue={name} onChange={(e) => { cleanMessage(); setName(e.target.value) }} required>
                                </Input>
                            </FormGroup>
                        </Col>

                        <Button className="btn-green mx-2 rounded-lg px-4 py-2" type="submit"
                            disabled={buttonDisabled || sendingRequest}
                        >
                            {sendingRequest ? (
                                <Spinner animation="border" role="status">

                                </Spinner>
                            ) : (
                                t("fpThird.actualizar")
                            )}
                        </Button>


                    </Form>
                </Modal.Body>
            </Modal>
            {!resumeScenario.isFetching &&
                <div>
                    <div className='flex justify-center mt-8'>

                        <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>

                    </div>
                    <div className='text-center mt-8'>
                        <p>Cargando...</p>
                    </div>
                </div>

            }
            {resumeScenario.isFetching && resumeScenario.data?.length === 0 &&
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    <div className="max-w-2xl m-auto mt-16">
                        <div className="text-center px-4">
                            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">

                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                        <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                    </path>
                                    <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                        <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                    </path>
                                    <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                        <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                    </path>
                                </svg>
                            </div>
                            <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">¡Reducir tu Huella de Carbono será más fácil!</h2>
                            <div className="mb-6">
                                <p> Aun no tienes escenenarios creados. </p>
                                <p>¡Crea el primero para empezar a  marcar la diferencia!</p>                                    </div>
                        </div>
                    </div>
                </div>
            }
            {resumeScenario.isFetching && resumeScenario.data?.length !== 0 &&
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">

                        <div className="container-simple-table mb-8">
                            <table className="simple-table">
                                <thead className="">
                                    <tr>
                                        <th> <div className="text-left">{t("decarbonization.scenarioName")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.periodo")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.ObjetivoScopes1and2")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.ObjetivoScope3")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.percentComplete")}</div></th>
                                        <th> <div className="text-left"></div></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        resumeScenario.isFetching &&
                                        resumeScenario?.data.map((data, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <a href={`/footprint/decarbonization/scenario/${data.id}`}>{data.name}</a></td>
                                                <td>{data.baseYear} - {data.targetYear}</td>
                                                <td>{formatThousands(data.targetScopes1and2)}%</td>
                                                <td>{formatThousands(data.targetScope3)}%</td>
                                                <td>    <div className="progress-bar-descarbonization">
                                                    <div
                                                        className="progress-bar-fill"
                                                        style={{ width: `${formatThousands(data.percentAsigned)}%` }}
                                                    />
                                                    <div className="progress-bar-text">
                                                        {formatThousands(data.percentAsigned)}%
                                                    </div>
                                                </div></td>
                                                <td className='flex gap-1 items-center'>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipEditar}
                                                    >
                                                        <button onClick={() => handleShowEdit(data)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-text)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                <path d="M16 5l3 3" />
                                                            </svg>
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipVerDetalle}
                                                    >
                                                        <button onClick={() => navigate(`scenario/${data.id}`)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-text)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                                            </svg>
                                                        </button>

                                                    </OverlayTrigger>

                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </main>
            }
        </div>

    )
}
