

import React, { useContext, useState } from 'react';
import actua from '../../../images/G_03.webp'
import mide from '../../../images/G_02.webp'
import reporta from '../../../images/reporta.png'
import certifica from '../../../images/certifica.png'
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
export default function BloqueImgSlide() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [category, setCategory] = useState(0)

    const images = [mide, actua, reporta, certifica]
    const url = "https://links.bastidafarina.com/widget/bookings/reunion-30-source-web-airco2"
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 text-airco2-general-grey">
                <div className="py-12 md:py-20" data-aos="fade-up">
                    {/* Section header */}
                    <div className="mx-auto text-center pb-5">
                        <h2 className="h2 font-cabinet-grotesk">  {t("newHome.mejorSolucion")}</h2>
                    </div>

                    <div>
                        {/* Category buttons */}
                        <div className="pb-5" data-aos="fade-up">
                            <div className="flex flex-wrap justify-center gap-4 ">
                                <button
                                    className={`btn m-1.5 h-8 shadow ${category === 0 ? 'sec-green-background text-white' : 'bg-white text-gray-500'}`}
                                    onClick={() => setCategory(0)}
                                >
                                    {t("newHome.mide")}
                                </button>
                                <button
                                    className={`btn m-1.5 h-8 shadow ${category === 1 ? 'sec-green-background text-white' : 'bg-white text-gray-500'}`}
                                    onClick={() => setCategory(1)}
                                >
                                    {t("newHome.actua")}
                                </button>
                                <button
                                    className={`btn m-1.5 h-8 shadow ${category === 2 ? 'sec-green-background text-white' : 'bg-white text-gray-500'}`}
                                    onClick={() => setCategory(2)}
                                >
                                    {t("newHome.reporta")}
                                </button>
                                <button
                                    className={`btn m-1.5 h-8 shadow ${category === 3 ? 'sec-green-background text-white' : 'bg-white text-gray-500'}`}
                                    onClick={() => setCategory(3)}
                                >
                                    {t("newHome.certifica")}
                                </button>
                            </div>
                        </div>

                        {/* Section content */}
                        <div className="max-w-xl mx-auto md:max-w-none flex flex-col items-center md:flex-row md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-8 space-y-reverse md:space-y-0">
                            {/* Content */}
                            <div className="w-full md:w-7/12" data-aos="fade-up">
                                {/* Content #1 */}
                                <div className={`${category !== 0 && 'hidden'}`}>
                                    <h3 className="h3 font-cabinet-grotesk mb-3">   {t("newHome.mide")}</h3>
                                    <p className="text-lg text-gray-500 mb-2">
                                        {t("newHome.midetxt")}
                                    </p>
                                    <ul className="inline-flex flex-col space-y-6">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.mide-title1")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.mide-text1")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.mide-title2")}</div>
                                                <div className="text-gray-500">
                                                    <ul>
                                                        <li>{t("newHome.mide-text2-1")}</li>
                                                        <li>{t("newHome.mide-text2-2")}</li>
                                                        <li>{t("newHome.mide-text2-3")}</li>
                                                        <li>{t("newHome.mide-text2-4")}</li>




                                                    </ul>


                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.mide-title3")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.mide-text3")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.mide-title4")}
                                                </div>
                                                <div className="text-gray-500">
                                                    {t("newHome.mide-text4")}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Content #2 */}
                                <div className={`${category !== 1 && 'hidden'}`}>
                                    <h3 className="h3 font-cabinet-grotesk mb-3">{t("newHome.actua")}</h3>
                                    <p className="text-lg text-gray-500 mb-2">
                                        {t("newHome.actuatxt")}
                                    </p>
                                    <ul className="inline-flex flex-col space-y-6">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.actua-title1")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.actua-text1")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.actua-title2")}
                                                </div>
                                                <div className="text-gray-500">
                                                    {t("newHome.actua-text2")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.actua-title3")}
                                                </div>
                                                <div className="text-gray-500">
                                                    {t("newHome.actua-text3")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.actua-title4")}
                                                </div>
                                                <div className="text-gray-500">
                                                    {t("newHome.actua-text4")}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Content #3 */}
                                <div className={`${category !== 2 && 'hidden'}`}>
                                    <h3 className="h3 font-cabinet-grotesk mb-3">{t("newHome.reporta")}</h3>
                                    <p className="text-lg text-gray-500 mb-2">
                                        {t("newHome.reportatxt")}
                                    </p>
                                    <ul className="inline-flex flex-col space-y-6">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.reporta-title1")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.reporta-text1")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.reporta-title2")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.reporta-text2")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.reporta-title3")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.reporta-text3")}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Content #4 */}
                                <div className={`${category !== 3 && 'hidden'}`}>
                                    <h3 className="h3 font-cabinet-grotesk mb-3">{t("newHome.certifica")}</h3>
                                    <p className="text-lg text-gray-500 mb-2">
                                        {t("newHome.certificatxt")}
                                    </p>
                                    <ul className="inline-flex flex-col space-y-6">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.certifica-title1")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.certifica-text1")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.certifica-title2")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.certifica-text2")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.certifica-title3")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.certifica-text3")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.certifica-title4")}</div>
                                                <div className="text-gray-500">
                                                    {t("newHome.certifica-text4")}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current  mt-1.5 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div>
                                                <div className="font-cabinet-grotesk font-bold text-lg mb-1">{t("newHome.certifica-title5")}</div>
                                                <div className="text-gray-500">
                                                {t("newHome.certifica-text5")}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Image */}
                            <div className="w-full md:w-5/12 " data-aos="fade-up" data-aos-delay="100">
                                <img src={images[category]}>
                                </img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-5 flex justify-center' style={{ marginBottom: '80px' }} data-aos="fade-up">
                <a className="btn bg-green-semitr text-airco2-general-grey  linkNav  w-1/2 shadow-sm" href={url} target='_blank'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-month mr-2" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
                        <path d="M16 3v4" />
                        <path d="M8 3v4" />
                        <path d="M4 11h16" />
                        <path d="M7 14h.013" />
                        <path d="M10.01 14h.005" />
                        <path d="M13.01 14h.005" />
                        <path d="M16.015 14h.005" />
                        <path d="M13.015 17h.005" />
                        <path d="M7.01 17h.005" />
                        <path d="M10.01 17h.005" />
                    </svg>
                    {t("hometext.btnagendademo")}
                </a>
            </div>
        </section>
    )
}